<template>
  <TheWrapper>
    <form
      novalidate="novalidate"
      class="wrapper__container refund"
      @submit.prevent="next"
    >
      <h2 class="step__main-title">
        {{ $t('How would you like to be refunded?') }}
      </h2>

      <ItemSelector
        v-for="option in refundOptions"
        :key="option.code"
        :field-id="`option-${option.code}`"
        :multiple="false"
        :value="option.code"
        :checked="option.code === code"
        group="refundOptions"
        class="refund-item"
        data-test="refund-option"
        @on-change="optionChanged"
      >
        <p class="refund-item__title">
          {{ option.label }}
        </p>
        <p
          v-if="option.code === 'money'"
          class="refund-item__subtitle"
        >
          {{ $t('Get back the cost of the items') }}
        </p>
        <p
          v-if="option.code === 'credit'"
          class="refund-item__subtitle"
        >
          {{ $t('Receive a store credit') }}
        </p>
        <p
          v-if="option.code === 'exchange'"
          class="refund-item__subtitle"
        >
          {{ $t('Ask for a different product, size, or other') }}
        </p>
      </ItemSelector>

      <div
        v-if="requireMessage"
        class="entry-message"
        data-test="message"
      >
        <label
          for="message"
          class="entry-message__label"
        >{{ $t('What would you like in exchange?') }}</label>
        <textarea
          id="message"
          v-model="message"
          name="message"
          rows="5"
          cols="30"
          class="entry-message__field"
          data-test="message-field"
        />
      </div>

      <UiButton
        mode="primary"
        class="main-button"
      >
        <button
          class="main-button__btn"
          data-test="main-button"
          :disabled="disableNext"
          :style="brandStyle"
        >
          <span v-if="completedAllSteps">{{ $t('Go to summary') }}</span>
          <span v-else>{{ $t('Choose how to return') }}</span>
          <FontAwesomeIcon :icon="$options.faChevronRight" />
        </button>
      </UiButton>
    </form>
  </TheWrapper>
</template>

<script>
import { APP_SET_SELECTED_REFUND } from '@/store/mutation.types.js'
import { brandColourMixin } from '@/components/mixins'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { handleRouteRejection } from '@/utils'
import { mapState } from 'vuex'
import { UiButton } from '@sendcloud/ui-library'
import ItemSelector from '@/components/common/ItemSelector.vue'
import TheWrapper from '@/components/layout/TheWrapper'

export default {
  name: 'RefundOptionsView',
  faChevronRight,
  components: {
    FontAwesomeIcon,
    ItemSelector,
    TheWrapper,
    UiButton,
  },
  mixins: [brandColourMixin],
  data() {
    return {
      message: '',
      code: undefined,
    }
  },
  computed: {
    ...mapState({
      refundOptions: (state) => state.refundOptions,
      completedAllSteps: (state) => state.completedAllSteps,
      savedOption: (state) => {
        if (state.selectedRefund !== undefined) return state.selectedRefund
        return { code: undefined, message: '' }
      },
    }),
    requireMessage() {
      const refundOption = this.refundOptions.find((x) => x.code === this.code)
      if (refundOption !== undefined) return refundOption.requireMessage
      return false
    },
    disableNext() {
      return this.code === undefined
    },
  },
  created() {
    this.message = this.savedOption.message
    this.code = this.savedOption.code
  },
  methods: {
    updateSelection(code, message) {
      this.$store.commit(APP_SET_SELECTED_REFUND, {
        code,
        message,
      })
    },
    next() {
      const message = this.requireMessage ? this.message : ''
      // SC-18402, only save the message once the user moves to the next step.
      this.updateSelection(this.code, message)
      return this.completedAllSteps
        ? this.$router.push({ name: 'summary' }).catch(handleRouteRejection)
        : this.$router.push({ name: 'return-methods' }).catch(handleRouteRejection)
    },
    optionChanged(_, option) {
      this.code = option
    },
  },
}
</script>
<style scoped lang="scss">
.refund-item__title {
  font-weight: 700;
  margin: 0;
}
.refund-item__subtitle {
  margin: 5px 0 0;
}
</style>
